import * as React from 'react';
import classNames from 'classnames';
import {
  IHtmlComponentProps,
  IHtmlComponentImperativeActions,
} from '../HtmlComponent.types';
import { useIFrame, IFrameEvent } from '../../../providers/useIFrame/useIFrame';
import * as translations from '../constants';
import { getDataAttributes } from '../../../core/commons/utils';
import styles from './styles/HtmlComponent.scss';

const HtmlComponent = React.forwardRef<
  IHtmlComponentImperativeActions,
  IHtmlComponentProps
>((props, forwardRef) => {
  const {
    id,
    className,
    url,
    allow,
    scrolling,
    title,
    sandbox,
    onMessage,
    onMouseEnter,
    onMouseLeave,
  } = props;
  const [render, setRender] = React.useState(false);

  React.useEffect(() => setRender(true), []);

  const onMessageHandler = React.useCallback(
    (event: IFrameEvent) => {
      try {
        if (event.type === 'message') {
          onMessage?.({
            data: event.payload,
            type: 'message',
          });
        }
      } catch (e) {
        return;
      }
    },
    [onMessage],
  );

  const getIframeTitle = () =>
    title ||
    props.translate!(
      translations.HTML_COMPONENT_NAMESPACE,
      translations.HTML_COMPONENT_TITLE_KEY,
      translations.HTML_COMPONENT_TITLE_DEFAULT,
    );

  const [ref, sendMessage] = useIFrame({ reducer: onMessageHandler });

  React.useImperativeHandle(forwardRef, () => ({
    postMessage(message) {
      sendMessage(message);
    },
  }));

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(className, styles.root)}
    >
      {render && (
        <wix-iframe data-src={url}>
          <div
            className={styles.iframeHolder}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <iframe
              ref={ref}
              sandbox={sandbox}
              className={styles.iframe}
              scrolling={scrolling}
              title={getIframeTitle()}
              name="htmlComp-iframe"
              width="100%"
              height="100%"
              allow={allow}
              data-src={url}
            />
          </div>
        </wix-iframe>
      )}
    </div>
  );
});

export default HtmlComponent;
